import { createApi } from "@reduxjs/toolkit/query/react";
import apiUrls from "src/config/api-urls";
import axiosBaseQuery from "./axiosBaseQuery";

const incidentService = createApi({
  reducerPath: "incident",
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getDailyReportsStaus: builder.query({
      query: (userUUID) => ({
        url: `${apiUrls.INCIDENT_GET_REPORT}/${userUUID}`,
        method: "get",
      }),
    }),
    updateDailyReportsStaus: builder.mutation({
      query: ({ userUUID, ...data }) => ({
        url: `${apiUrls.INCIDENT_GET_REPORT}/${userUUID}`,
        data,
        method: "put",
      }),
    }),
  }),
});
export const {
  useGetDailyReportsStausQuery,
  useUpdateDailyReportsStausMutation,
} = incidentService;

export default incidentService;
