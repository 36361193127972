import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import apiUrls from "../../config/api-urls";
import { ApiHelper } from "../api-helpers";
import { async } from "@firebase/util";

// ----------------------------------------------------------------------

const initialState = {
  isupdatePasswordDone: false,
  isLoading: false,
  error: false,
  isUpdateUser: false,
  myProfile: null,
  posts: [],
  users: [],
  user_photo: "",
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  company: null,
  companies: [],
  branches: null,
  companybranch: null,
  cashRoles: [],
  cashCompanies: [],
  cashCompanyBranches: [],
  branchEmployees: [],
  tokenVerify: null,
  err_msg: null,
  password: null,
};

const slice = createSlice({
  name: "user",
  initialState,

  reducers: {
    // SUCCESS
    userSuccess(state, action) {
      state.isUserDone = true;
      state.user = action.payload.user;
    },
    // HAS ERROR
    userError(state) {
      state.isUserDone = false;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload.myProfile;
      state.isUpdateUser = true;
    },

    userPhotoUpload(state, action) {
      state.isUserDone = true;
      state.user_photo = action.payload.photo;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(
        state.userList,
        (user) => user.id !== action.payload
      );
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed,
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // SUCCESS
    companySuccess(state, action) {
      state.company = action.payload.company;
    },
    // HAS ERROR
    companyError(state) {
      state.company = null;
    },

    // SUCCESS
    cashRoleSuccess(state, action) {
      state.cashRoles = action.payload.cashRoles;
    },
    // HAS ERROR
    cashRoleError(state) {
      state.cashRoles = null;
    },

    // SUCCESS
    cashCompaniesSuccess(state, action) {
      state.cashCompanies = action.payload.cashCompanies;
    },
    // HAS ERROR
    cashCompaniesError(state) {
      state.cashCompanies = null;
    },

    // SUCCESS
    cashBranchesSuccess(state, action) {
      state.cashCompanyBranches = action.payload.cashCompanyBranches;
    },
    // HAS ERROR
    cashBranchesError(state) {
      state.cashCompanyBranches = null;
    },

    // SUCCESS
    companiesSuccess(state, action) {
      state.companies = action.payload.companies;
    },
    // HAS ERROR
    companiesError(state) {
      state.companies = null;
    },

    // SUCCESS
    branchSuccess(state, action) {
      state.branches = action.payload.branches;
    },
    // HAS ERROR
    branchError(state) {
      state.branches = null;
    },

    // SUCCESS
    companybranchSuccess(state, action) {
      state.companybranch = action.payload.companybranch;
    },
    // HAS ERROR
    companybranchError(state) {
      state.companybranch = null;
    },
    BranchEmployeesSuccess(state, action) {
      state.isUserDone = true;
      state.branchEmployees = action.payload.branchEmployees;
    },
    // HAS ERROR
    BranchEmployeesError(state) {
      state.isUserDone = false;
    },
    // SUCCESS
    checkTokenSuccess(state, action) {
      state.tokenVerify = action.payload.tokenVerify;
    },
    // HAS ERROR
    checkTokenError(state) {
      state.tokenVerify = null;
    },
    // SUCCESS
    updatePasswordSuccess(state, action) {
      state.password = action.payload.password;
      state.isupdatePasswordDone = true;
    },
    // HAS ERROR
    updatePasswordError(state, action) {
      state.err_msg = action.payload.err_msg;
    },

    update_User_Unmount(state, action) {
      state.isUpdateUser = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser, update_User_Unmount } =
  slice.actions;

// ----------------------------------------------------------------------

export function updateUser(data, uid = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.USER_API_URL}/${uid}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.getProfileSuccess({
            myProfile: response.data,
          })
        );
        return response;
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.userError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

export function updateUserPassword(data, uid = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.USER_UPDATE_PASSWORD}/${uid}`,
      data: data,
      method: "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        // if (!apiRes && response.data.error) {
        //   Error(response.data);
        //   throw TypeError(response.data.message);
        // }
        dispatch(
          slice.actions.updatePasswordSuccess({
            password: response.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.updatePasswordError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
export function DeleteUser(uid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.INVITE_USER_URL}/${uid}`,

      method: "DELETE",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.userSuccess({
            user: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.userError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
export function postUserPhoto(data, userId) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.USER_PHOTO_UPLOAD}/${userId}`,
      data: data,
      image: true,
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.getProfileSuccess({
            myProfile: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.userError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/posts");
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/followers");
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/friends");
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/social/gallery");
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
//***Original code */
export function getUserList(bid, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await ApiHelper({
      url: `${apiUrls.INVITE_USER_FETCH_ALL_URL}/${bid}`,
      method: "GET",
      data: data,
    })
      .then((response) => {
        dispatch(slice.actions.getUserListSuccess(response.data.data.result));
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.hasError(_err));
      });
  };
}

// ----------------------------------------------------------------------

export function getCompany(com_id) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.COMPANY_FETCH}/${com_id}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(slice.actions.companySuccess({ company: response.data.data }));
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.companyError());
      });
  };
}

export function getCompanies(uid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.COMPANY_FETCH_ALL}/${uid}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.companiesSuccess({ companies: response.data.data })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.companiesError());
      });
  };
}

export function getBranches(uid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.BRANCH_FETCH_ALL_EID}/${uid}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(slice.actions.branchSuccess({ branches: response.data.data }));
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.branchError());
      });
  };
}

export function getCompanyBranches(cid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.BRANCH_FETCH_ALL_CID}/${cid}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.companybranchSuccess({
            companybranch: response.data.data,
          })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.companybranchError());
      });
  };
}

export function getCashRoles() {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.ROLE_FETCH_ALL}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.cashRoleSuccess({ cashRoles: response.data.result })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.cashRoleError());
      });
  };
}

export function getCashCompanies(id) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.CASH_COMPANY_FETCH_ALL}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.cashCompaniesSuccess({
            cashCompanies: response.data.data,
          })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.cashCompaniesError());
      });
  };
}

export function getCashCompanyBranches(cid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.BRANCH_FETCH_ALL}/${cid}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.cashBranchesSuccess({
            cashCompanyBranches: response.data.data ? response.data.data : [],
          })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.cashBranchesError());
      });
  };
}

// ----------------------------------------------------------------------

export function getBranchEmployees(bid) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.EMPLOYEES_FETCH_ALL}/${bid}`,
      method: "GET",
    })
      .then((response) => {
        dispatch(
          slice.actions.BranchEmployeesSuccess({
            branchEmployees: response.data.data.result,
          })
        );
      })
      .catch((_err) => {
        console.log(_err);
        dispatch(slice.actions.BranchEmployeesError());
      });
  };
}

//-----------------------------------------------------------------------------
export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/account/address-book");
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/account/invoices");
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        "/api/user/account/notifications-settings"
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/user/all");
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//------------------------------------------------------------------------
export function updateFcmToken(data, uid = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.EMPLOYEE_UPDATE_FCM_URL}/${uid}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.userSuccess({
            users: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.userError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
export function check_password_token(data) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.CHECK_PASSWORD_URL}`,
      data: data,
      method: "POST",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.checkTokenSuccess({
            tokenVerify: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.checkTokenError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}

//------------------------------------------------------------------------
