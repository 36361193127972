import React from 'react';
import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_DASHBOARD} from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({children}) {
  const {isAuthenticated, company, branch, isLoginCheckDone} = useAuth();
  if (isLoginCheckDone && isAuthenticated) {
      return <Navigate to={PATH_DASHBOARD.general.incidents} />;
  }

  return <>{children}</>;
}
