import React from "react";
import { Icon } from "@iconify/react";
import googleFill from "@iconify/icons-eva/google-fill";
// material
import { Stack, Button, Divider, Typography } from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";

// ----------------------------------------------------------------------

export default function AuthFirebaseSocials() {
  const { loginWithGoogle } = useAuth();

  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={handleLoginGoogle}
        >
          <Icon icon={googleFill} color="#DF3E30" height={24} />
        </Button>

      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
