import React from "react";
import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 165, height: 50, ...sx }}>
      <Box
        component="img"
        src={`/static/transparent_modern_visu_ai.png`}
        placeholder="Visu AI image"
      />
    </Box>
  );
}
