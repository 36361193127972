//import jwtDecode from "jwt-decode";
import { isExpired, decodeToken } from "react-jwt";
import { PATH_AUTH } from "../routes/paths";
import axios from "./axios";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const TokenExpired = isExpired(accessToken);
  return TokenExpired;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    localStorage.removeItem("accessToken");
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    //This function below will handle when token is expired
    const dectoken = decodeToken(accessToken);
    handleTokenExpired(dectoken.exp);
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
