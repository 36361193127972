
const { ApiHelper } = require("../api-helpers");

const axiosBaseQuery = async ({
  url,
  data = {},
  method = "POST",
  image = false,
}) => {
  try {
    let { data: result } = await ApiHelper({ url, data, method, image });

    if (result?.error) {
      return {
        error: {
          status: result?.status,
          data: result?.message,
        },
      };
    }
    return {
      data: result?.data,
    };
  } catch (axiosError) {
    let err = axiosError;
    console.log("API Error", err);
    return {
      error: {
        status: err?.status,
        data: err?.message || "Someting went wrong.",
        message: err?.message || "Someting went wrong.",
      },
    };
  }
};

export default axiosBaseQuery;
