import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import userReducer from "./slices/user";
import branchReducer from "./slices/branch";
import filterReducer from "./slices/filter";
import branchService from "./apiServices/branchService";
import companyService from "./apiServices/companyService";
import incidentService from "./apiServices/incidentService";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  user: userReducer,
  branch: branchReducer,
  filter: filterReducer,
  [branchService.reducerPath]: branchService.reducer,
  [companyService.reducerPath]: companyService.reducer,
  [incidentService.reducerPath]: incidentService.reducer,
});

export { rootPersistConfig, rootReducer };
