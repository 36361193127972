module.exports = {
  domain: "https://sso.visu.ai",
  incidentDomain: "https://analystapi.visu.ai",
  //cdnDomain: "https://dwicqqt130lxl.cloudfront.net",
  // cdnDomain: "https://d1qmc00ovlu2eq.cloudfront.net/",
  cdnDomain: "https://dccuaam3stwc8.cloudfront.net/",
  newcdnDomain: "https://dccuaam3stwc8.cloudfront.net/",
  // transactionDomain: "http://3.22.245.186:3068",
  transactionDomain: "https://postransactions.visu.ai",
};
