import React from 'react';
// hooks
import useAuth from '../hooks/useAuth';
//
import {MAvatar} from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({...other}) {
  let {user} = useAuth();
  let firstChar = user ? user.first_name.charAt(0) : null;
  user = {photoURL: null, displayName: firstChar ? firstChar : null};
  return (
      <MAvatar
          src={user.photoURL}
          alt={user.displayName}
          color={user.photoURL ?
              'default' :
              createAvatar(user.displayName).color}
          {...other}>
        {createAvatar(user.displayName).name}
      </MAvatar>
  );
}
