import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "../../redux/store";
import {
  getBranches,
  getCompanies,
  getCompany,
  getCompanyBranches,
} from "../../redux/slices/user";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 160;
const APP_BAR_DESKTOP = 62;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
  },
}));

// ----------------------------------------------------------------------
export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { user, isLoginCheckDone, company, permission } = useAuth();
  // const {company} = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoginCheckDone) {
      if (permission && permission["permission"].includes("company_View")) {
        dispatch(getCompany(user.id));
      }
      if (permission && permission["permission"].includes("branch_List")) {
        dispatch(getBranches(user.id));
      }
      //dispatch(getBranches(user.id));
      dispatch(getCompanies(user.id));
      // dispatch(getCompanyBranches(company.id));
    }
  }, [dispatch]);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
          ...(collapseClick && { ml: "102px" }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
