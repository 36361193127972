const domain = require("./config").domain;
const incidentDomain = require("./config").incidentDomain;
const cdnDomain = require("./config").cdnDomain;
const newcdnDomain = require("./config").newcdnDomain;
const transactionDomain = require("./config").transactionDomain;

const apiUrls = {
  ANALYST_API_URL: `${incidentDomain}`,
  LOGIN_API_URL: `${incidentDomain}/api/auth/login`,
  CHECK_PASSWORD_URL: `${domain}/api/auth/check-password-token`,

  FETCH_BRANCH_API_URL: `${domain}/api/branch/search`,

  COMPANY_API_URL: `${domain}/api/company/fetch/All`,
  POST_COMPANY_URL: `${domain}/api/company`,
  COMPANY_FETCH: `${domain}/api/company/fetch`,
  BRANCH_FETCH_ALL_EID: `${domain}/api/branch/fetch/all`,
  BRANCH_FETCH_ALL_CID: `${domain}/api/branch/fetch`,
  COMPANY_FETCH_ALL: `${domain}/api/company/fetch`,
  COMPANY_PHOTO_UPLOAD: `${domain}/api/company/photo/upload`,
  COMPANY_PHOTO: `${domain}/api/company/photo/`,
  COMPANY_COVER: `${domain}/api/company/cover/`,
  BRANCH_PHOTO_UPLOAD: `${domain}/api/branch/photo/upload`,
  USER_PHOTO_UPLOAD: `${domain}/api/employee/photo/upload`,
  USER_PHOTO: `${domain}/api/employee/photo/`,
  USER_API_URL: `${domain}/api/employee`,
  USER_UPDATE_PASSWORD: `${domain}/api/employee/reset-password`, //need employee id
  BRANCH_API_URL: `${domain}/api/branch`,
  FETCH_COMPANY_API_URL: `${domain}/api/company/search`,
  INVITE_USER_URL: `${domain}/api/branch-invites`,
  FETCH_USER: `${incidentDomain}/api/auth/login/check`,
  FETCH_USER_BY_ID: `${domain}/api/employee/fetch`,
  DEFAULT_BRANCH_API_URL: `${domain}/api/branch/default`,

  //Incident Domain URL's
  INCIDENT_URL: `${incidentDomain}/api/video_analyst`,
  INCIDENT_FETCH_ALL: `${incidentDomain}/api/video_analyst`,
  UPDATE_INCIDENT_URL: `${incidentDomain}/api/video_analyst`,
  ALLINCIDENT_FETCH_URL: `${incidentDomain}/api/incident/search`,
  INCIDENT_FETCHBYCAMERA_ID_URL: `${incidentDomain}api/incident/fetch/all`, //need camera id
  INCIDENT_FETCHBY_DATE_URL: `${incidentDomain}/api/incident/date`, //need DAY OR DATE

  //Cashiers URL's
  CREATE_CASHIER_URL: `${incidentDomain}/api/cashier`,
  READ_ALL_CASHIERS_URL: `${incidentDomain}/api/cashier`,
  UPDATE_CASHIER_URL: `${incidentDomain}/api/video_analyst`,
  DELETE_CASHIER_URL: `${incidentDomain}/api/video_analyst`,
  CASHIER_PHOTO_UPLOAD: `${incidentDomain}/api/cashier/photo/upload`,
  CASHIER_PHOTO: `${incidentDomain}/api/cashier/photo/`,

  //Products URL's
  CREATE_PRODUCT_URL: `${incidentDomain}/api/product`,
  READ_ALL_PRODUCTS_URL: `${incidentDomain}/api/product`,
  UPDATE_PRODUCT_URL: `${incidentDomain}/api/product`,
  DELETE_PRODUCT_URL: `${incidentDomain}/api/product`,
  PRODUCT_PHOTO_UPLOAD: `${incidentDomain}/api/product/photo/upload`,
  PRODUCT_PHOTO: `${incidentDomain}/api/product/photo/`,

  ROLE_FETCH_ALL: `${domain}/api/cashier/roles`,
  CASH_COMPANY_FETCH_ALL: `${domain}/api/cashier/companies`,
  BRANCH_FETCH_ALL: `${domain}/api/cashier/branches`,

  NEXT_PREV_URL: `${transactionDomain}/get_transaction_data`,

  //S3 CDN Domain
  INCIDENT_S3_CDN_URL: `${cdnDomain}`,
  NEWINCIDENT_S3_CDN_URL: `${newcdnDomain}`,
};

export default apiUrls;
