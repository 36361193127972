import { createSlice } from "@reduxjs/toolkit";
// utils
import apiUrls from "../../config/api-urls";
import { ApiHelper } from "../api-helpers";

// ----------------------------------------------------------------------

const initialState = {
  isDefaultBranchDone: false,
  DefaultBranch: null,
};

const slice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    //Success
    defaultBranchSuccess(state, action) {
      state.isDefaultBranchDone = true;
      state.DefaultBranch = action.payload.DefaultBranch;
    },
    // HAS ERROR
    defaultBranchError(state) {
      state.isDefaultBranchDone = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { defaultBranchSuccess, defaultBranchError } = slice.actions;

export function postDefaultBranch(data, bid = null) {
  return async (dispatch) => {
    await ApiHelper({
      url: `${apiUrls.DEFAULT_BRANCH_API_URL}/${bid}`,
      data: data,
      method: "PUT",
    })
      .then((response) => {
        const apiRes = response.data ? response.data.data : null;
        if (!apiRes && response.data) {
          throw TypeError(response.data.message);
        }
        dispatch(
          slice.actions.defaultBranchSuccess({
            DefaultBranch: response.data.data,
          })
        );
      })
      .catch((apiResErr) => {
        dispatch(
          slice.actions.defaultBranchError({
            err_msg:
              apiResErr.error || apiResErr.message
                ? apiResErr.message
                : "Something went wrong",
          })
        );
      });
  };
}
