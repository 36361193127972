import { createApi } from "@reduxjs/toolkit/query/react";
import apiUrls from "src/config/api-urls";
import axiosBaseQuery from "./axiosBaseQuery";

const branchService = createApi({
  reducerPath: "branch",
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    findBranch: builder.query({
      query: (companyUUID = "") => ({
        url: `${apiUrls.BRANCH_FETCH_ALL_CID}/${companyUUID}`,
        method: "get",
      }),
    }),
    findBranchById: builder.query({
      query: (branchUUID = "") => ({
        url: `${apiUrls.BRANCH_API_URL}/${branchUUID}`,
        method: "get",
      }),
    }),
    createBranch: builder.mutation({
      query: (data) => ({ url: `${apiUrls.BRANCH_API_URL}`, data }),
    }),
    updateBranch: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${apiUrls.BRANCH_API_URL}/${id}`,
        data,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useFindBranchQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useFindBranchByIdQuery,
} = branchService;

export default branchService;
