import axios from "../utils/axios";

export function ApiHelper({ url, data = {}, method = "POST", image = false }) {
  return new Promise((resolve, reject) => {
    // let bearer = 'Bearer ' + localStorage.getItem('accessToken');
    let options = {
      // Return promise
      url: url,
      method: method || "POST",
      cache: "no-cache",
    };
    let headers = {};
    if (image) {
      headers["Content-Type"] = "multipart/form-data";
      options["data"] = data;
    } else if (method === "GET" || method === "get") {
      headers["Accept"] = "application/json";
    } else {
      headers["Content-Type"] = "application/json";
      headers["Accept"] = "application/json";
      options["data"] = JSON.stringify(data);
    }
    options["headers"] = headers;
    axios(options)
      .then((response) => {
        resolve(response);
      })
      .catch((_err) => {
        reject(_err);
      });
  });
}
