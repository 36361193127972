import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
//
import { rootPersistConfig, rootReducer } from "./rootReducer";
import middlewares from "./middlewares";

// ----------------------------------------------------------------------
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    ...middlewares,
  ],
});
const persistor = persistStore(store);
let useSelector = () => {
  return { user: {}, userList: [] };
};
if (typeof window !== "undefined") {
  useSelector = useReduxSelector;
}
const useDispatch = () => {
  try {
    return useReduxDispatch();
  } catch (e) {
    return null;
  }
};

export { store, persistor, useSelector, useDispatch };
