import { createSlice } from "@reduxjs/toolkit";
// utils
import apiUrls from "../../config/api-urls";
import { ApiHelper } from "../api-helpers";

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  offset: "",
  searchTerm: null,
  visibleIncidents: null,
  selectedValues: {
    allfiltered: [],
  },
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterVals(state, action) {
      state.filtervalues = action.payload;
      state.offset = "valuetype";
    },
    search(state, action) {
      state.searchTerm = action.payload;
      state.offset = "searchtype";
    },
    filterReducer(state = initialState, action) {
      state.selectedValues = action.payload;
    },
    startLoading(state, action) {
      state.loading = true;
    },
    stopLoading(state, action) {
      state.loading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { typeReset, setFilterVals, update, search } = slice.actions;

export function updateFilter(filtervalues) {
  return (dispatch) => {
    dispatch(slice.actions.setFilterVals(filtervalues));
  };
}
export function updateSearchTerm(valueofType) {
  return (dispatch) => {
    dispatch(slice.actions.search(valueofType));
  };
}

export const setFilterSelectedValues = (values) => {
  return (dispatch) => {
    dispatch(slice.actions.filterReducer(values));
  };
};
