import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef, useEffect, useCallback} from 'react';
// material
import {Box} from '@mui/material';
// utils
// import ThemeConfig from '../theme';
// import {CollapseDrawerProvider} from '../contexts/CollapseDrawerContext';
import "../styles/main.scss"
// import '../locales/i18n'
import '../utils/highlight'
// import 'mapbox-gl/dist/mapbox-gl.css';
// import * as mapboxgl from 'mapbox-gl';
import 'react-image-lightbox/style.css';
// import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {useLocation} from 'react-router-dom';
import track from '../utils/analytics';
import "leaflet-draw/dist/leaflet.draw.css"
// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = '', ...other}, ref) => {
  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);
  return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {/*<ThemeConfig>*/}
        {/*  <NotistackProvider>*/}
        {/*    <CollapseDrawerProvider>*/}
              {children}
            {/*</CollapseDrawerProvider>*/}
          {/*</NotistackProvider>*/}
        {/*</ThemeConfig>*/}
      </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
