import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography, Grid, Box } from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import AuthFirebaseSocials from "../../components/authentication/AuthFirebaseSocial";
// hooks
import Logo from "../../components/Logo";
import { Link as RouterLink } from "react-router-dom";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    textAlign: "center",
  },
}));

const LogoStyle = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: 0,
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: 0,
}));

export default function Login() {
  return (
    <RootStyle title="Login">
      <Container component="main" maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <MHidden width="mdDown">
              <SectionStyle>
                <Typography variant="h3" sx={{ padding: "0 0 50px 40px" }}>
                  Hi, Welcome Back
                </Typography>
                <Box
                  component="img"
                  src="/static/illustrations/illustration_login.png"
                  alt="login"
                />
              </SectionStyle>
            </MHidden>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ textAlign: "left" }}>
            <ContentStyle>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <LogoStyle
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={PATH_AUTH.login}
                  >
                    <Logo sx={{ margin: "10px 0 15px -10px" }} />
                  </LogoStyle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ padding: "0 0 10px 0px" }}
                >
                  <Typography
                    sx={{
                      color: "text.secondary",
                      padding: "10px 10px 10px 0px",
                    }}
                  >
                    Enter your details below.
                  </Typography>
                </Grid>
              </Grid>
              {/* <AuthFirebaseSocials/> */}
              <LoginForm />
              {/* <MHidden width="xlUp">
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don't have an account?&nbsp;
                  <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={PATH_AUTH.register}
                  >
                    Get started
                  </Link>
                </Typography>
              </MHidden> */}
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
