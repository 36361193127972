import { createApi } from "@reduxjs/toolkit/query/react";
import apiUrls from "src/config/api-urls";
import axiosBaseQuery from "./axiosBaseQuery";

const companyService = createApi({
  reducerPath: "company",
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    findCompany: builder.query({
      query: (companyUUID = "") => ({
        url: `${apiUrls.COMPANY_API_URL}/${companyUUID}`,
        method: "get",
      }),
    }),
    // findCompanyById: builder.query({
    //     query: (companyUUID = '') => ({ url: `${apiUrls.COMPANY_API_URL}/${companyUUID}`, method: 'get' })
    // }),
    createCompany: builder.mutation({
      query: (data) => ({ url: `${apiUrls.POST_COMPANY_URL}`, data }),
    }),
    updateCompany: builder.mutation({
      query: ({ companyUUID, ...data }) => ({
        url: `${apiUrls.POST_COMPANY_URL}/${companyUUID}`,
        data,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useFindCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
} = companyService;

export default companyService;
/*/fetch/All/:id",*/
