import React from "react";
import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import apiUrls from "../config/api-urls";
// utils
import { setSession, isValidToken } from "../utils/jwt";
import { ApiHelper } from "../redux/api-helpers";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  company: null,
  branch: null,
  permission: [],
  isLoginCheckDone: false,
  isTokenCheckDone: false,
  isEmailSent: null,
  branchName: [],
  companyName: [],
};

const handlers = {
  UPDATE: (state, action) => {
    let { payload } = action;
    payload = payload || {};
    return {
      ...state,
      ...payload,
    };
  },
  INITIALIZE: (state, action) => {
    const {
      isAuthenticated,
      user,
      company,
      branch,
      companyName,
      branchName,
      permission,
      // role,
      isLoginCheckDone,
    } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isLoginCheckDone,
      user,
      company,
      branch,
      companyName,
      branchName,
      permission,
      // role,
    };
  },
  LOGIN: (state, action) => {
    const {
      user,
      company,
      branch,
      branchdefault,
      branchName,
      companyName,
      permission,
      isAuthenticated,
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      user,
      company,
      branch,
      companyName,
      branchName,
      permission,
      // role,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    company: null,
    permission: null,
  }),
  SETBRANCH: (state, action) => {
    const { branchName } = action.payload;
    return {
      ...state,
      branchName,
    };
  },
  // SETCOMPANY: (state, action) => {
  //   const { companyName } = action.payload;
  //   return {
  //     ...state,
  //     companyName,
  //   };
  // },
  SETCOMPANY: (state, action) => {
    const { company } = action.payload;
    return {
      ...state,
      company,
    };
  },
  SETTOKEN: (state, action) => {
    const { isTokenCheckDone } = action.payload;
    return {
      ...state,
      isTokenCheckDone,
      // role,
    };
  },
  SETRESETTOKEN: (state, action) => {
    const { isEmailSent } = action.payload;
    return {
      ...state,
      isEmailSent,
      // role,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "firebase",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  // register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function handleAuthRes(response, dispatch, msgStr) {
  const Res = response.data ? response.data.data : null;
  let apiRes = Res ? Res : null;
  if (Array.isArray(Res)) {
    apiRes = Res ? Res[0] : null;
  }
  if (!apiRes) {
    msgStr = response.data.message;
  }
  if (!apiRes && response.data) {
    msgStr = response.data.message;
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isLoginCheckDone: true,
      },
    });
  } else {
    const { token } = apiRes;
    const isTokenvalid = !isValidToken(token);
    if (isTokenvalid) {
      setSession(token);
      dispatch({
        type: "INITIALIZE",
        payload: {
          user: apiRes,
          isAuthenticated: true,
          isLoginCheckDone: true,
          // company: apiRes.employee_companies.length
          //   ? apiRes["employee_companies"][0]["company"]
          //   : null,
          // companyName: apiRes.employee_companies.length ?
          // apiRes['employee_companies'][0]['company'] : null,

          // branch: apiRes.employee_branches.length
          //   ? apiRes["employee_branches"][0]["branch"]
          //   : null,
          // branchNmae: apiRes.employee_branches.length ?
          // apiRes['employee_branches'][0]['branch'] : null,
          permission: Res ? Res[1] : [],
          //apiRes['employee_roles'][0]['role'] : null,
        },
      });
    } else {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          isLoginCheckDone: true,
        },
      });
    }
  }
  if (msgStr) {
    throw TypeError(msgStr);
  }
  return msgStr;
}

function handleAuthResError(e, msgStr) {
  console.log("err---", e);
  console.log("err---", msgStr);
  if (e.errors) {
    e.errors.map((v, i) => {
      msgStr = `${msgStr} ${v.msg}`;
    });
  }
  throw TypeError(
    msgStr ? msgStr : e.error || e.message ? e.message : "Something went wrong"
  );
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated: false,
        isLoginCheckDone: false,
      },
    });
    const initialize = async () => {
      try {
        let accessToken = window.localStorage.getItem("accessToken");
        if (!accessToken) {
          return dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              isLoginCheckDone: true,
            },
          });
        }
        const data = { token: accessToken };
        await ApiHelper({
          url: apiUrls.FETCH_USER,
          data: data,
        }).then((response) => {
          handleAuthRes(response, dispatch);
        });
      } catch (e) {
        console.log(e);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            isLoginCheckDone: true,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    let msgStr = "";
    try {
      await ApiHelper({
        url: apiUrls.LOGIN_API_URL,
        data: {
          email,
          password,
        },
      }).then((response) => {
        handleAuthRes(response, dispatch);
      });
    } catch (e) {
      handleAuthResError(e, msgStr);
    }
  };

  // const branchNameHandler = (e) => {
  //   dispatch({
  //     type: 'SETBRANCH',
  //     payload: {x
  //       branchName: e
  //     },
  //   });
  // };

  const companyNameHandler = (e) => {
    dispatch({
      type: "SETCOMPANY",
      payload: {
        companyName: e,
      },
    });
  };
  const updateCompany = (e) => {
    dispatch({
      type: "SETCOMPANY",
      payload: {
        company: e,
      },
    });
  };
  // const register = async (data) => {
  //   let msgStr = "";
  //   try {
  //     await ApiHelper({
  //       url: apiUrls.REGISTER_API_URL,
  //       data: data,
  //     }).then((response) => {
  //       handleAuthRes(response, dispatch, msgStr);
  //     });
  //   } catch (e) {
  //     handleAuthResError(e, msgStr);
  //   }
  // };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  // const resetPassword = async (email) => {
  //   let msgStr = "user do not exist";
  //   try {
  //     await ApiHelper({
  //       url: apiUrls.FORGOT_PASSWORD_URL,
  //       data: email,
  //     }).then((response) => {
  //       if (response.data.status === "success") {
  //         dispatch({
  //           type: "SETRESETTOKEN",
  //           payload: {
  //             isEmailSent: true,
  //           },
  //         });
  //       } else if (response.data.status === "error") {
  //         msgStr = response.data.message;
  //         dispatch({
  //           type: "SETRESETTOKEN",
  //           payload: {
  //             isEmailSent: false,
  //           },
  //         });
  //         handleAuthResError();
  //       }
  //     });
  //   } catch (e) {
  //     handleAuthResError(e, msgStr);
  //   }
  // };
  // const newPassword = async (email, password, token) => {
  //   let msgStr = "password reset error";
  //   try {
  //     await ApiHelper({
  //       url: apiUrls.CREATE_NEW_PASSWORD_URL,
  //       data: {
  //         email: email,
  //         password: password,
  //         token: token,
  //       },
  //     }).then((response) => {
  //       if (response.data.status === "success") {
  //         dispatch({
  //           type: "SETTOKEN",
  //           payload: {
  //             isTokenCheckDone: true,
  //           },
  //         });
  //       } else if (response.data.status === "error") {
  //         msgStr = response.data.message;
  //         dispatch({
  //           type: "SETTOKEN",
  //           payload: {
  //             isTokenCheckDone: null,
  //           },
  //         });
  //         handleAuthResError();
  //       }
  //     });
  //   } catch (e) {
  //     handleAuthResError(e, msgStr);
  //   }
  // };
  // const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        // register,
        // resetPassword,
        // updateProfile,
        authDispatch: dispatch,
        // newPassword,
        // branchNameHandler,
        // companyNameHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
