import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "",
          element: <Navigate to="/login" replace />,
        },
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password/:token", element: <NewPassword /> },
        { path: "update-success/:bool", element: <UpdateSuccess /> },
        { path: "welcome-success/:bool", element: <WelcomeSuccess /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "profile", element: <UserProfile /> },
        // { path: "company", element: <Company /> },
        // { path: "companies", element: <Companies /> },
        { path: "incidents", element: <Incidents /> },
        {
          path: "incidents/:cashiername/:branchname/:event/:duration/:eventname",
          element: <Incidents />,
        },
        { path: "incident-details-new/:id", element: <IncidentDetailsNew /> },
        { path: "cashiers", element: <Cashiers /> },
        { path: "cashier-create/:id", element: <CashierCreate /> },
        { path: "cashier-create/", element: <CashierCreate /> },
        { path: "products", element: <Products /> },
        { path: "product-create/:id", element: <ProductCreate /> },
        { path: "product-create/", element: <ProductCreate /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/login")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/reset-password"))
);
const NewPassword = Loadable(lazy(() => import("../pages/auth/new-password")));
const UpdateSuccess = Loadable(
  lazy(() => import("../pages/auth/welcome-success"))
);
const WelcomeSuccess = Loadable(
  lazy(() => import("../pages/auth/update-success"))
);
const UserProfile = Loadable(lazy(() => import("../pages/dashboard/profile")));
const Incidents = Loadable(lazy(() => import("../pages/dashboard/incidents")));
const IncidentDetailsNew = Loadable(
  lazy(() => import("../pages/dashboard/incident-details-new"))
);
const Cashiers = Loadable(lazy(() => import("../pages/dashboard/cashiers")));
const CashierCreate = Loadable(
  lazy(() => import("../pages/dashboard/cashier-create"))
);
const Products = Loadable(lazy(() => import("../pages/dashboard/products")));
const ProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/product-create"))
);

// Main
const Page500 = Loadable(lazy(() => import("../pages/500")));
const NotFound = Loadable(lazy(() => import("../pages/404")));
